/* General section: */
.bg-themeColor {
  background-color: var(--themeColor);
}
.bg-backgroundOne {
  background-color: var(--backgroundOne);
}
.bg-backgroundOne\/80 {
  background-color: var(--backgroundOne80);
}
.bg-backgroundOne\/40 {
  background-color: var(--backgroundOne40);
}
.bg-backgroundTwo {
  background-color: var(--backgroundTwo);
}
.bg-backgroundTwo60 {
  background-color: var(--backgroundTwo60);
  --tw-backdrop-blur: blur(1rem);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bg-backgroundThree {
  background-color: var(--backgroundThree);
}
.bg-barColor {
  background-color: var(--barColor);
}
.bg-barColor85 {
  background-color: var(--barColor85);
  --tw-backdrop-blur: blur(1rem);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.bg-alertBackground {
  background-color: var(--alertBackground);
}

.text-themeColor {
  color: var(--themeColor);
}
.text-textPrimary {
  color: var(--textPrimary);
}
.text-textSecondary {
  color: var(--textSecondary);
}
.text-iconAndText {
  color: var(--iconAndText);
}
.text-hoverIconAndText {
  color: var(--hoverIconAndText);
}
.text-inputIcon {
  color: var(--inputIcon);
}
.text-stepsIconAndText {
  color: var(--stepsIconAndText);
}
.text-backgroundOne {
  color: var(--backgroundOne);
}
.text-stepsIconAndTextReproved {
  color: var(--stepsIconAndTextReproved);
}

.important-text-textPrimary, .important-text-textPrimary p, .important-text-textPrimary span {
  color: var(--textPrimary) !important;
}

.group\/mainObject:hover .group-hover\/mainObject\:text-textPrimary {
  color: var(--textPrimary);
}

@media not all and (min-width: 1025px) {
  .max-lg\:hover\:text-gray-500:hover {
    color: var(--inputIcon);
  }
}

@media not all and (min-width: 1025px) {
  .max-lg\:bg-backgroundOne {
    background-color: var(--backgroundOne);
  }
}

@media not all and (min-width: 1171px) {
  .max-mb\:bg-transparent {
      background-color: transparent !important;
  }
}

.border-themeColor {
  border-color: var(--themeColor);
}
.border-stepReproved {
  border-color: var(--stepReproved);
}

.border-backgroundTwo {
  border-color: var(--backgroundTwo);
}
.border-backgroundThree {
  border-color: var(--backgroundThree);
}

/* Hover section:  */
.hover-text-textPrimary:hover {
  color: var(--textPrimary);
}
.hover-text-textSecondary:hover {
  color: var(--textSecondary);
}
.hover-text-themeColor:hover {
  color: var(--themeColor);
}
.hover-text-hoverIconAndText:hover {
  color: var(--hoverIconAndText);
}

.hover-bg-themeColor:hover {
  background-color: var(--themeColor);
}

/* Group Hover Section */
.group:hover .group-hover-text-themeColor {
  color: var(--themeColor);
}
.group:hover .group-hover-text-textPrimary {
  color: var(--textPrimary);
}
.group:hover .group-hover-text-textSecondary {
  color: var(--textSecondary);
}

.group:hover .group-hover-bg-themeColor {
  background-color: var(--themeColor);
}
.group\/mainObject:hover .group-hover\/mainObject\:bg-themeColor {
  background-color: var(--themeColor);
}

.text-stroke-themeColor {
  --tst-text-stroke-color: var(--themeColor);
}

.from-backgroundOne-via-72 {
  --tw-gradient-from: var(--backgroundOne) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--backgroundOne72) var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.from-backgroundOne {
  --tw-gradient-from: var(--backgroundOne) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-backgroundTwo-via-50 {
  --tw-gradient-from: var(--backgroundTwo) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--backgroundTwo) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.from-backgroundThree {
  --tw-gradient-from: var(--backgroundThree) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

@media not all and (min-width: 769px) {
  .max-md\:from-transparent {
  --tw-gradient-from: rgb(0 0 0 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
}

/* Using WhiteLabel Elements: */
.span-small-white {
  @apply text-left text-sm font-semibold;
}
.link-style {
  @apply font-normal text-textSecondary text-sm cursor-pointer transition-all duration-300 hover-text-themeColor;
}
.link-white-style {
  @apply font-normal text-textPrimary text-[0.875rem] cursor-pointer transition-all duration-300 hover-text-themeColor;
}
.description-style {
  @apply font-normal text-textSecondary text-sm max-lg:leading-tight;
}
.slide-item {
  @apply w-full h-[80%]
}