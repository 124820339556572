@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.last-message-container {
  animation: fadeInUp 0.3s ease-in-out forwards;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}

.assistant-message-animated {
  position: relative;
  overflow: hidden;
}

.assistant-message-animated::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  transform: translateY(-100%);
  animation: revealText 5s forwards;
}

@keyframes revealText {
  to {
    transform: translateY(100%);
  }
}

.assistant-message-text div {
  margin-bottom: 18px;
  line-height: 1.75;
}

.lia-gradient {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(
    135deg,
    #a31d50 0%,
    #de3b83 25%,
    #f35297 50%,
    #de3b83 75%,
    #a31d50 100%
  );
}

.shine-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, var(--textSecondary) 0, white 10%, var(--textSecondary) 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 100%;
  }
  100% {
    background-position: 200px;
  }
}

:root {
  --color-violet: 234, 0, 95;
  --color-orange: 135, 30, 72;
  --color-black: 10, 10, 10;
  --color-white: 211, 93, 141;
  --rotation-duration: 3s;
  --dynamic-border-radius: 55%;
}

.scale-2 {
  scale: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: rotate(50deg); */
}

.lia-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  border-radius: var(
    --dynamic-border-radius
  ); /* Usando o border-radius dinâmico */
  box-shadow: 0 0 20px 15px rgba(var(--color-orange), 0.05);
  animation: rotateShape var(--rotation-duration) infinite ease-in-out;
}

.lia-circle-1 {
  background: radial-gradient(
    circle,
    rgba(var(--color-black), 0) 0%,
    rgba(var(--color-black), 0) 55%,
    rgba(var(--color-orange), 1) 71%,
    rgba(var(--color-orange), 1) 100%
  );
}

/* Segunda esfera rotacionando com luz violeta */
.lia-circle-2 {
  background: radial-gradient(
    circle,
    rgba(var(--color-black), 0) 0%,
    rgba(var(--color-black), 0) 69%,
    rgba(var(--color-violet), 1) 200%,
    rgba(var(--color-violet), 1) 100%
  );
  animation: rotateShape var(--rotation-duration) infinite reverse ease-in-out;
}

/* Terceira esfera com luz branca */
.lia-circle-3 {
  background: radial-gradient(
    circle,
    rgba(var(--color-black), 0) 0%,
    rgba(var(--color-black), 0) 69%,
    rgba(var(--color-white), 1) 70%,
    rgba(var(--color-white), 1) 100%
  );
}

/* Animação de rotação */
@keyframes rotateShape {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    border-radius: var(--dynamic-border-radius);
  }
  50% {
    transform: translate(-50%, -50%) rotate(180deg) scale(1.05);
    border-radius: 57% 47% 60% 50%; /* Deformação adicional durante a animação */
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1);
    border-radius: var(--dynamic-border-radius); /* Retorna ao valor dinâmico */
  }
}
