@keyframes ripple {
  to {
    transform: scale(1.6);
    opacity: 0;
  }
}

.floatwp:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  border-radius: 100px !important;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid #08b158;
  animation: ripple 2s cubic-bezier(0.23, 1, 0.32, 1) infinite both !important;
}

.floatwp {
  border-radius: 50px;
  box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 6px 8px 2px rgba(0, 0, 0, 0.14);
}

.floatwp,
.floatlia {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  z-index: 120;
  bottom: 3vw;
  right: 3vw;
}

@media (max-width: 1024px) {
  .floatwp,
  .floatlia {
    bottom: 10%;
    right: 5vw;
  }
}