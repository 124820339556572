body {
  background-color: black;
}

input,
button,
textarea,
select,
option {
  outline: none;
}

/* Input CSS - Start */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: var(--themeColor);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--backgroundTwo) inset !important;
  caret-color: var(--textPrimary);
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--textPrimary) !important;
}

.transition-300 {
  @apply transition-all duration-300 ease-in-out;
}

.transition-600 {
  @apply transition-all duration-[0.6s] ease-in-out;
}

.check-container {
  display: flex;
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--backgroundTwo);
  border-radius: 0.25rem;
  transition: all 0.3s;
}
.check-container:hover input ~ .checkmark {
  background-color: #4e4e55;
}
.check-container input:checked ~ .checkmark {
  background-color: var(--themeColor);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check-container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-container input:checked ~ .checkmark:after {
  display: block;
}
/* Input CSS - END */

/* Scroll Settings - Start */
body::-webkit-scrollbar-thumb,
.custom-dark-scroll::-webkit-scrollbar-thumb {
  background-color: var(--themeColor);
  border-radius: 1.25rem;
}

body::-webkit-scrollbar {
  width: 7px !important;
}

.custom-dark-scroll::-webkit-scrollbar {
  width: 4px !important;
}

.custom-small-horizontal-scroll::-webkit-scrollbar {
  height: 7px !important;
}

.custom-transparent-scroll::-webkit-scrollbar {
  width: 0px !important;
}

body::-webkit-scrollbar-track,
.custom-dark-scroll::-webkit-scrollbar-track {
  background: var(--backgroundOne);
}

@media (max-width: 1024px) {
  body::-webkit-scrollbar,
  .custom-dark-scroll::-webkit-scrollbar {
    width: 0 !important;
  }
}

/* Scroll Settings - End */

.custom-transparent-scroll-background::-webkit-scrollbar-track {
  background: transparent !important;
}

.prose-sm :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}

.prose-sm :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
  font-weight: 700;
}

.prose-sm :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5714286em;
  list-style-type: none;
  margin-bottom: 1.1428571em;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(93%)
    contrast(90%);
}

.important-opacity-0 {
  opacity: 0 !important;
}

.fadeInOpacityClass {
  animation: fadeInOpacity 0.6s ease forwards;
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInScale {
  from {
    /* opacity: 0; */
    transform: scale(0.9);
  }
  to {
    /* opacity: 1; */
    transform: scale(1);
  }
}

@keyframes fadeOutScale {
  from {
    /* opacity: 1; */
    transform: scale(1);
  }
  to {
    /* opacity: 0; */
    transform: scale(0.9);
  }
}

.animate-clickAnimation {
  animation: clickAnimation 0.3s ease-out;
}

@keyframes clickAnimation {
  from,
  to {
    transform: scaleX(1) scaleY(1);
  }
  50% {
    transform: scaleX(1.2) scaleY(1.2);
  }
}

.animate-appear {
  animation: appearOpacity 0.3s ease-out;
}

@keyframes appearOpacity {
  from,
  20% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-disappear {
  animation: disappearOpacity 0.3s ease-out;
}

@keyframes disappearOpacity {
  from,
  20% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 100;
}

/* .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 335px;
  height: 592px; 
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 101;
} */

.modal-content.show {
  transform: translate(-50%, -50%) scale(1);
}

.video-content {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.video-content.show {
  opacity: 1;
}